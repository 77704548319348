const moment = require("moment");
const {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  EN_ATTENTE_BANQUE,
  PROSPECT,
} = require("./devis_contrat_status");
const getSimpleDateFR = (date) => {
  if (!date) return date;
  if (typeof date == "string" && date.includes("/")) {
    return date;
  }
  return moment(date).format("DD/MM/YYYY");
};
const getSimpleDateTimeFR = (date) => {
  if (!date) return date;
  if (typeof date == "string" && date.includes("/")) {
    return date;
  }
  return moment(date).format("DD/MM/YYYY HH:mm");
};

const getSimpleTimeFR = (date) => {
  if (!date) return date;
  if (typeof date == "string" && date.includes("/")) {
    return date;
  }
  return moment(date).format("HH:mm");
};

const toCurrency = (val, trunk) => {
  if (isNaN(val)) return '"non défini"';
  let text = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(val);
  return trunk ? text.replace(",00", "") : text;
};

const toPercentage = (val) => {
  if (isNaN(val)) return '"non défini"';
  let text = new Intl.NumberFormat("fr-FR", {
    style: "percent",
    minimumFractionDigits: 2,
  }).format(val);
  return text;

}

const toFrCurrencyValue = (val) => {
  if (isNaN(val)) return '"non défini"';
  let text = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(val);
  return text.replace("€", "").trim();
};

const toCurrencyWithZero = (val) => {
  val = isNaN(val) ? 0 : val;
  return toCurrency(val);
}

const getMonthOptions = () => {
  let options = [];
  for (let i = 0; i < 12; i++) {
    let startDate = moment().subtract(i, "month").startOf("month").toDate();
    let endDate = moment().subtract(i, "month").endOf("month").toDate();
    let name = moment().subtract(i, "month").format("MM/YYYY");
    if (i === 0) name = "Mois en cours";
    options.push({ startDate, endDate, name });
  }
  let startDate = moment().subtract(11, "month").startOf("month").toDate();
  let endDate = new Date();
  let name = "12 derniers mois";
  options.push({ startDate, endDate, name });
  return options;
};

const getSorted = (data, sort) => {
  if (!sort || !(data instanceof Array)) return data;
  console.log('data ', data);
  return (data || []).sort((d1, d2) =>
    sort === "DESC"
      ? d1.createdAt > d2.createdAt
        ? -1
        : 1
      : d1.createdAt > d2.createdAt
        ? 1
        : -1
  );
};

const filterOnPaymentStatus = (data, display_status_stripe) => {
  console.log(display_status_stripe);
  console.log(data.filter(d => !display_status_stripe || d.display_status_stripe === display_status_stripe));
  return data.filter(d => !display_status_stripe || d.display_status_stripe === display_status_stripe);
}
const getNumberOfAppel = (devis) => {
  if (!devis) return 0;
  switch (devis.status) {
    case PROSPECT:
      return devis.nbr_appel_prospect;
    case DEAL_POSSIBLE:
      return devis.nbr_appel_deal_possible;
    case DEAL_POSSIBLE_REMPLI:
      return devis.nbr_appel_deal_rempli;
    case DEVIS:
      return devis.nbr_appel_devis;
    case DEVIS_VALIDE:
      return devis.nbr_appel_devis_valide;
    case EN_ATTENTE_DE_RESILIATION:
      return devis.nbr_appel_resiliation;
    case EN_ATTENTE_BANQUE:
      return devis.nbr_appel_attente_banque;
    case EN_ATTENTE_DE_FORMALITE_MEDICALE:
      return devis.nbr_appel_formalite_medicale;
    case CONTRAT_ENVOYE:
      return devis.nbr_appel_contrat;
    case CONTRAT_CLOTURE:
      return devis.nbr_appel_cloture;
    default:
      return 0;
  }
};
const toArrayOfArray = (original, length = 3) => {
  if (!original || original.length === 0) return original;
  let result = [];
  let group = [];
  for (let i = 0; i < original.length; i++) {
    if (i > 0 && i % length === 0) {
      result.push(group);
      group = [];
    }
    group.push(original[i]);
  }
  result.push(group);
  return result;
};

const getDateCurrentStatus = (devis) => {
  if (!devis) return 0;
  switch (devis.status) {
    case DEAL_POSSIBLE:
      return getSimpleDateFR(devis.createdAt);
    case DEAL_POSSIBLE_REMPLI:
      return getSimpleDateFR(devis.date_deal_rempli);
    case DEVIS:
      return getSimpleDateFR(devis.date_devis);
    case DEVIS_VALIDE:
      return getSimpleDateFR(devis.date_devis_valide);
    case EN_ATTENTE_DE_RESILIATION:
      return getSimpleDateFR(devis.date_resiliation);
    case EN_ATTENTE_DE_FORMALITE_MEDICALE:
      return getSimpleDateFR(devis.date_formalite_medicale);
    case CONTRAT_ENVOYE:
    case EN_ATTENTE_BANQUE:
      return getSimpleDateFR(devis.date_contrat);
    case CONTRAT_CLOTURE:
      return getSimpleDateFR(devis.date_cloture);
    default:
      return 0;
  }
};
const getDateCurrentStatusField = (devis) => {
  if (!devis) return 0;
  switch (devis.status) {
    case DEAL_POSSIBLE:
      return "createdAt";
    case PROSPECT:
      return "createdAt";
    case DEAL_POSSIBLE_REMPLI:
      return "date_deal_rempli";
    case DEVIS:
      return "date_devis";
    case DEVIS_VALIDE:
      return "date_devis_valide";
    case EN_ATTENTE_DE_RESILIATION:
      return "date_resiliation";
    case EN_ATTENTE_DE_FORMALITE_MEDICALE:
      return "date_formalite_medicale";
    case EN_ATTENTE_BANQUE:
    case CONTRAT_ENVOYE:
      return "date_contrat";
    case CONTRAT_CLOTURE:
      return "date_cloture";
    default:
      return "updatedAt";
  }
};


const getApporteurName = devis => {
  let name = devis.apporteur ? (`${devis.apporteur.prenom} ${devis.apporteur.nom}`) : (devis.provider_name || devis.agence_name);
  if (!name) {
    name = devis.affiliation?.agence_name;
  }
  return name;
}

const isValidEmailAndPhone = contact => {
  return isValidEmail(contact) && isValidPhone(contact);
}
const isValidEmail = contact => {
  if (!contact.email) return false;
  const splits = contact.email.split('@');
  console.log(splits);
  if (splits.length !== 2) return false;
  const beforeAt = splits[0];
  const afterAt = splits[1];
  console.log(beforeAt, afterAt);
  if (!beforeAt || !afterAt) return false;
  const composeAfters = afterAt.split('.');
  console.log(composeAfters);
  if (composeAfters.length < 2) return false;
  if (composeAfters[0]?.length < 2 || composeAfters[1]?.length < 2) return false;
  return true;
}

const isValidPhone = contact => {
  if (!contact.phone) return false;
  if (contact.phone.startsWith("33")) {
    contact.phone = "+" + contact.phone;
  }
  let phone = contact.phone;
  phone = phone
    .replace(/\)/g, '')
    .replace(/\(/g, '');
  if (phone.startsWith('0033') || phone.startsWith('+33')) {
    phone = phone.replace('0033', '').replace('+33', '')
      .trim();
    if (!phone.startsWith('0')) {
      phone = '0' + phone;
    }
  }
  phone = phone.replace(/ /g, '')
    .replace(/-/g, '')
    .replace(/\./g, '');
  console.log('phone: ', phone);
  if (phone.length !== 10) return false;
  if (!phone.startsWith('0')) return false;
  return !isNaN(parseInt(phone.substring(1, phone.length)))

}

module.exports = {
  getSimpleDateFR,
  getSimpleDateTimeFR,
  toCurrency,
  toCurrencyWithZero,
  getSorted,
  getMonthOptions,
  getNumberOfAppel,
  getDateCurrentStatus,
  getDateCurrentStatusField,
  toArrayOfArray,
  getApporteurName,
  toFrCurrencyValue,
  getSimpleTimeFR,
  isValidPhone,
  isValidEmail,
  filterOnPaymentStatus,
  toPercentage
};
