import { Box, Text, TextInput } from 'grommet';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import CustomInput from '../../elements/custom_input';
import Line from '../../elements/line_container';
import LineModification from '../line_container/line_modification';

export const AddressAutoCompletV2 = ({ contact, updateContact, disabled, direction = 'column' }) => {

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g",
    onPlaceSelected: (place) => {
      let parts = place.address_components;
      let address = parts.filter(s => s.types && (s.types.includes('street_number') || s.types.includes('route'))).map(s => s.long_name).join(' ');
      let zip = parts.filter(s => s.types && s.types.includes('postal_code')).map(s => s.long_name).join('');
      let city = parts.filter(s => s.types && s.types.includes('locality')).map(s => s.long_name).join('');
      updateContact('address', address);
      updateContact('zip', zip);
      updateContact('city', city);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: "fr" },
    }
  })


  return (
    <>
      <LineV2
        leftLabel={<Text size="xsmall" color={'#707070'}>Adresse principale</Text>}
        rightChildren={
          <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
            size="xsmall"
            value={contact.address}
            placeholder='Adresse principale'
            ref={ref}
            onChange={event => updateContact('address', event.target.value)}
          />
        }
      />
      <LineV2
        leftLabel={<Text size="xsmall" color={'#707070'}>Code postal</Text>}
        rightChildren={
          <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
            size="xsmall"
            value={contact.zip}
            onChange={event => updateContact('zip', event.target.value)}
          />
        }
      />
      <LineV2
        leftLabel={<Text size="xsmall" color={'#707070'}>Ville</Text>}
        rightChildren={
          <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
            size="xsmall"
            value={contact.city}
            onChange={event => updateContact('city', event.target.value)}
          />
        }
      />
    </>
  )
}

const CustomTextV2 = ({ label }) => (
  <Text size="small" textAlign="start">
    {label}
  </Text>
);

const LineV2 = ({ leftLabel, rightChildren }) => false ?
  (
    <Box
      style={{ width: "100%" }}
      pad='small'
    >
      <Box>
        <CustomTextV2 label={leftLabel ? leftLabel + " :" : ""} textAlign='start' />
      </Box>
      <Box >{rightChildren}</Box>
    </Box>
  ) : (
    <Box
      direction="column"
      style={{ width: "100%" }}
      align="start"
      alignSelf="start"
    >
      <Box style={{ width: "100%" }}>
        <CustomTextV2 textAlign="start" label={leftLabel} />
      </Box>
      <Box style={{ width: "100%" }}>{rightChildren}</Box>
    </Box>
  );



const AddressAutoComplet = ({ contact, updateContact, disabled, direction = 'column' }) => {

    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g",
        onPlaceSelected: (place) => {
            let parts = place.address_components;
            let address = parts.filter(s => s.types && (s.types.includes('street_number') || s.types.includes('route'))).map(s => s.long_name).join(' ');
            let zip = parts.filter(s => s.types && s.types.includes('postal_code')).map(s => s.long_name).join('');
            let city = parts.filter(s => s.types && s.types.includes('locality')).map(s => s.long_name).join('');
            updateContact('address', address);
            updateContact('zip', zip);
            updateContact('city', city);
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: "fr" },
        }
    })


    return (
        direction === 'in-line' ?
            <Box style={{ width: '100%' }} gap="xsmall">
                <Box gap="xxsmall" justify="start" align="center" width={'large'}>
                    <InLineComponent
                        left={<Text size="xsmall" color={'#707070'}>Adresse principale</Text>}
                        right={
                            <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
                                size="xsmall"
                                value={contact.address}
                                placeholder='Adresse principale'
                                ref={ref}
                                onChange={event => updateContact('address', event.target.value)}
                            />
                        }
                    />
                    <InLineComponent
                        left={<Text size="xsmall" color={'#707070'}>Code postal</Text>}
                        right={
                            <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
                                size="xsmall"
                                value={contact.zip}
                                onChange={event => updateContact('zip', event.target.value)}
                            />
                        }
                    />
                    <InLineComponent
                        left={<Text size="xsmall" color={'#707070'}>Ville</Text>}
                        right={
                            <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
                                size="xsmall"
                                value={contact.city}
                                onChange={event => updateContact('city', event.target.value)}
                            />
                        }
                    />
                </Box>

            </Box> :

            direction === 'row' ?

                <Box style={{ width: '100%' }} gap="xsmall">
                    <Box gap="xsmall" justify="start" align="center" width={'large'}>
                        <Box style={{ width: '100%' }}>
                            <Text size="xsmall" color={'#707070'}>Adresse principale</Text>
                        </Box>
                        <Box style={{ width: '100%' }}>
                            <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }}
                                size="xsmall"
                                value={contact.address}
                                ref={ref}
                                onChange={event => updateContact('address', event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box direction="row" gap="small">
                        <LineModification text='Code postal' field='zip'
                            value={contact.zip}
                            direction='column'
                            onChange={updateContact}
                        />
                        <LineModification text='Ville' field='city'
                            value={contact.city}
                            direction='column'
                            onChange={updateContact}
                        />
                    </Box>

                </Box> :
                <Box style={{ width: '100%' }} gap="xsmall">
                    <Line
                        leftLabel="Adresse principale"
                        rightChildren={
                            <TextInput size={'small'}
                                ref={ref}
                                style={{ background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none' }}
                                value={contact.address}
                                onChange={event => updateContact('address', event.target.value)}
                                disabled={disabled}
                            />
                        }
                    />
                    <Line
                        leftLabel="Code postal"
                        rightChildren={
                            <CustomInput
                                disabled={disabled}
                                value={contact.zip}
                                onChange={event => updateContact('zip', event.target.value)}
                            />
                        }
                    />
                    <Line
                        leftLabel="Ville"
                        rightChildren={
                            <CustomInput
                                disabled={disabled}
                                value={contact.city}
                                onChange={event => updateContact('city', event.target.value)}
                            />
                        }
                    />
                </Box>
    )
}


const InLineComponent = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}


export default AddressAutoComplet;

