import { Text } from "grommet";
import React from "react";

const StandardText = ({ label, size, bold, ...rest }) => (
    <Text size={size} color={'#707070'} style={{fontWeight: bold ? 'bold' : 'normal'}} {...rest}>
        {label && typeof label.split === 'function' ? 
            label.split('<br/>').map((s, index) => (<span key={`span_${index}`}>{s}<br/></span>)) :
            label
        }
    </Text>
)

export default StandardText ;