const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};

const createEmprunteurHorsligne = (devis) => {
    return axios.post(`${baseAPI}/public/emprunteur-horsligne`, devis, { headers: HEADERS });
}

const getEmprunteurHorslignes = (client_id, query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: query, status, _limit: -1 };
    let binome_id = storageService.getBinomeId();
    if(binome_id) {
        delete params.commercial_id;
        params['_where[0][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
        delete params['_where[0][commercial_id_in]']
    }
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }
    return axios.get(`${baseAPI}/public/emprunteur-horsligne`, { params, headers: HEADERS });
}

const getEmprunteurHorslignesById = (id) => {
    return axios.get(`${baseAPI}/public/emprunteur-horsligne/${id}`, { headers: HEADERS });
}

const updateEmprunteurHorsligne = (devis) => {
    return axios.put(`${baseAPI}/public/emprunteur-horsligne/${devis.id}`, devis, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciale/emprunteur-horsligne/send`, devis, { headers: HEADERS });
}

const sendSimulation = (devis) => {
  return axios.post(`${baseAPI}/commerciale/emprunteur-horsligne/simulation`, devis, { headers: HEADERS });
}

const sendYousign = (body) => {
    return axios.post(`${baseAPI}/public/emprunteur-horsligne/sendYousign`, body, { headers: HEADERS });
}

const sendRelanceSignature = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-signature`, devis, { headers: HEADERS });
}
const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

module.exports = {
    createEmprunteurHorsligne,
    updateEmprunteurHorsligne,
    getEmprunteurHorslignes,
    sendForm,
    sendYousign,
    sendRelanceSignature,
    sendRelancePayment,
    sendSimulation,
    getEmprunteurHorslignesById
}