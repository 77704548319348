import { Box, Button, CheckBox, Grid, Text } from 'grommet';
import React from 'react';
import { toArrayOfArray } from '../../../services/utils';

const baseStyle = { border: 'none', cursor: 'pointer', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, }
const ToggleButton = ({ groups, fontSize, disabled, size, gap }) => {
    let fontSizeStyle = fontSize ? { fontSize } : {};
    let activeStyle = { ...fontSizeStyle, ...{ background: '#E82C70', color: '#fff' }, ...baseStyle };
    let inactiveStyle = { ...fontSizeStyle, ...{ background: '#EDEDED', color: '#a2a2a3' }, ...baseStyle };

    return (
        <Box direction="row" gap={gap}>
            {groups ?
                groups.map((button, index) => {

                    let borderStyle = {};
                    if (index === 0) {
                        borderStyle = {
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                        };
                    }
                    else if (index === groups.length - 1) {
                        borderStyle = {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10
                        };
                    } else {
                        borderStyle = { borderRadius: 0 };
                    }
                    return (
                        <Button primary
                            disabled={disabled}
                            key={index + 1}
                            size={'small'}
                            style={button.selected ? Object.assign(borderStyle, activeStyle) : Object.assign(borderStyle, inactiveStyle)}
                            onClick={button.onClick}>
                            {button.label}
                            {button.desc && <><br/><Text style={{fontSize: 10}}>{button.desc}</Text></>}
                        </Button>
                    )
                }) : null
            }
        </Box>
    )
}

const baseStyleV2 = { border: 'none', cursor: 'pointer', paddingTop: 7, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, }
export const ToggleButtonV2 = ({ groups, fontSize = 14, disabled, size }) => {
    let fontSizeStyle = fontSize ? { fontSize } : {};
    let activeStyle = { ...fontSizeStyle, ...{ background: '#E92C70', color: '#FFFFFF' }, ...baseStyleV2 };
    let inactiveStyle = { ...fontSizeStyle, ...{ background: '#EDEDED', color: '#a2a2a3' }, ...baseStyleV2 };

    return (
        <Box width="fit-content">
            <Box direction="row" pad="3px" gap='xsmall' background="#EDEDED" style={{ borderRadius: 10 }}>
                {groups ?
                    groups.map((button, index) => {
                        let borderStyle = { borderRadius: 10 };
                        return (
                            <Button primary
                                disabled={disabled}
                                key={index + 1}
                                size={'small'}
                                style={button.selected ? Object.assign(borderStyle, activeStyle) : Object.assign(borderStyle, inactiveStyle)}
                                onClick={button.onClick}>
                                {button.label}
                            </Button>
                        )
                    }) : null
                }
            </Box>
        </Box>
    )
}

export default ToggleButton;

export const MultipleLineToggleButtons = ({ groups, size = 4, activeColor = '#59a4f4' }) => {
    const activeStyle = { background: activeColor, border: 'none', cursor: 'pointer', color: '#fff', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };
    const inactiveStyle = { background: '#EDEDED', border: 'none', cursor: 'pointer', color: '#a2a2a3', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };

    return (
        <Box gap="medium" width={'100%'}>
            {
                toArrayOfArray(groups, size).map((gr, idx) => (
                    <Grid
                        columns={{
                            count: size,
                            size: 'auto'
                        }}
                        key={idx}
                        gap={'xsmall'}
                    >
                        {
                            gr.map((button, index) => {

                                let borderStyle = { borderRadius: 10, boxShadow: '3px 3px 3px #c8C8C8' };
                                return (
                                    <Button primary
                                        key={index + 1}
                                        style={button.selected ? Object.assign(borderStyle, activeStyle) : Object.assign(borderStyle, inactiveStyle)}
                                        onClick={button.onClick}>
                                        <Box align='center' justify='center'>
                                            <Text size='xsmall' textAlign='center'>{button.label}</Text>
                                        </Box>
                                    </Button>
                                )
                            })
                        }
                    </Grid>
                ))
            }
        </Box>
    )

}


export const MultipleLineCheckBoxes = ({ groups, size = 4, activeColor = '#59a4f4', disabled }) => {
    const activeStyle = { background: activeColor, border: 'none', cursor: 'pointer', color: '#fff', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };
    const inactiveStyle = { background: '#EDEDED', border: 'none', cursor: 'pointer', color: '#a2a2a3', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };
    groups = groups.sort((a, b) => a.group - b.group);

    return (
        <Box fill gap='xsmall'>
            {
                toArrayOfArray(groups, size).map((gr, idx) => (
                    <Grid
                        columns={{
                            count: size,
                            size: 'auto'
                        }}
                        key={idx}
                    >
                        {
                            gr.map((button, index) => {
                                return (
                                    <CheckBox
                                        disabled={disabled}
                                        key={index + 1}
                                        label={<Text size='small'>{button.label}</Text>}
                                        checked={button.selected}
                                        onChange={button.onClick} >
                                    </CheckBox>
                                )
                            })
                        }
                    </Grid>
                ))
            }
        </Box>
    )

}