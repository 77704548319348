const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const getAllCompanies = () => {
    return axios.get(`${baseAPI}/commerciales/companies`, { headers: HEADERS });
}
const getAllTypeContrat = () => {
    return axios.get(`${baseAPI}/commerciales/property/type_contrat`, { headers: HEADERS });
}
const getContact = (page = 0, query = '', type) => {
    let commercial_id = storageService.getCommercialId();
    let params = { _q: query, hubspot_owner_id: commercial_id };
    if(storageService.hasAdministrativeRole()) {
        delete params.hubspot_owner_id;
    }
    return axios.get(`${baseAPI}/public/clients`, { params: { _q: query }, headers: HEADERS });
}

const getContactById = id => {
    return axios.get(`${baseAPI}/public/clients/${id}`, { headers: HEADERS });
}

const createContact = (contact) => {
    contact.hubspot_owner_id = storageService.getCommercialId();
    return axios.post(`${baseAPI}/public/clients`, contact, { headers: HEADERS });
}
const deleteContact = (contactId) => {
    return axios.delete(`${baseAPI}/public/clients/${contactId}`, { headers: HEADERS });
}
const updateContact = (contact) => {
    return axios.put(`${baseAPI}/public/clients/${contact.id}`, contact, { headers: HEADERS });
}

const getHabitationDevis = (contactId) => {
    return axios.get(`${baseAPI}/commerciales/contacts/${contactId}/habitation-devis/`, { headers: HEADERS });
}
const getTrustiHabitations = (client_id, _query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: _query, status, _limit: -1 };
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
    }
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }
    return axios.get(`${baseAPI}/public/devis-habitations/`, { params, headers: HEADERS });
}

const createHabitationDevis = (devis) => {
    return axios.post(`${baseAPI}/commerciales/habitation-devis/`, devis, { headers: HEADERS });
}

const updateHabitationDevis = (devis) => {
    return axios.put(`${baseAPI}/commerciales/habitation-devis/${devis.id}`, devis, { headers: HEADERS });
}

const deleteHabitationDevis = (contactId, devisId) => {
    return axios.delete(`${baseAPI}/commerciales/contacts/${contactId}/habitation-devis/${devisId}`, { headers: HEADERS });
}

const estimateDevis = (devis) => {
    return axios.post(`${baseAPI}/commerciales/habitation-devis/price-estimation`, devis, { headers: HEADERS });
}

const sendDevis = (devis) => {
    return axios.post(`${baseAPI}/commerciales/habitation-devis/send-estimation`, devis, { headers: HEADERS });
}

const getMessageReport = (messageId) => {
    return axios.get(`${baseAPI}/commerciales/email-report/${messageId}`, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciales/habitation-devis/send-form`, devis, { headers: HEADERS });
}

const sendDevisEmail = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-devis-email`, devis, { headers: HEADERS });
}

const sendContratEmail = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-contrat-email`, devis, { headers: HEADERS });
}

const getClosedContractByClient = (client_id) => {
    return axios.get(`${baseAPI}/closed-contract/${client_id}`, { headers: HEADERS });
}

const getDealsByStatusAndClient = (client_id, status) => {
    return axios.get(`${baseAPI}/clients/${client_id}/deals`, { headers: HEADERS, params:{status} });
}

const getAssociatedEntreprises = (contactId) => {
    let params= {
        '_where[0][interlocuteurs.id]': contactId
    }
    return axios.get(`${baseAPI}/public/entreprises`, { params, headers: HEADERS });
}

const changeCommercialOwner = (newCommercialId, clientId, entrepriseId) => {
    return axios.post(`${baseAPI}/public/clients/commercial`, {newCommercialId, clientId, entrepriseId}, { headers: HEADERS });
}

module.exports = {
    getContact,
    getContactById,
    createContact,
    updateContact,
    deleteContact,
    getAssociatedEntreprises,
    getHabitationDevis,
    getTrustiHabitations,
    createHabitationDevis,
    updateHabitationDevis,
    deleteHabitationDevis,
    estimateDevis,
    sendDevis,
    sendForm,
    getMessageReport,
    getAllCompanies,
    sendDevisEmail,
    sendContratEmail,
    getClosedContractByClient,
    getDealsByStatusAndClient,
    getAllTypeContrat,
    changeCommercialOwner,
}