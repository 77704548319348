import { Box, Select, Text } from "grommet";
import React, { useState } from "react";
import CustomInput from "../../elements/custom_input";
import Line from "../../elements/line_container";
import ToggleButton from "../../elements/toggle_button/toggle_button";
import { CIVILITY, CONTACT_TYPE } from "../../../services/types";
import { getContact } from "../../../services/contact_service";
import AddressAutoComplet from "../../elements/address_autocomplete";
import DateInput from "../../elements/date_input";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const renderContact = (s) =>
  s
    ? `${s.firstname || ""} ${s.lastname || ""} (${s.email
    })`
    : "";

const initContacts = (contact) => {
  if (!contact || !contact.hubspot_contact_id) return [];
  contact.description = `${contact.firstname || ""} ${contact.lastname || ""} (${contact.email
    })`;
  return [contact];
};
const ContactSelect = ({
  contact,
  updateContact,
  addressIncluded,
  birthDayIncluded,
  withCreateNew = true,
  title = "Renseigné vos informations",
  isPro = false,
  size = 'small'
}) => {
  let timeoutId;

  const [contacts, setContacts] = useState(initContacts(contact));
  const [contactDesc, setContactDesc] = useState(
    contact.firstname +
    " " +
    contact.lastname +
    " " +
    `(${contact.email || ""})`
  );
  const [isContact, setIsContact] = useState(
    contact.id ? true : withCreateNew ? false : true
  );

  const updateSelectedContact = (text) => {
    let value = contacts.find((s) => renderContact(s) === text);
    setContactDesc(text);
    updateClient("id", value.id);
    updateClient("civilite", value.civilite);
    updateClient("contact_type", value.contact_type);
    updateClient("hubspot_contact_id", value.hubspot_contact_id);
    updateClient("firstname", value.firstname);
    updateClient("lastname", value.lastname);
    updateClient("email", value.email);
    updateClient("phone", value.phone);
    updateClient("address", value.address);
    updateClient("zip", value.zip);
    updateClient("city", value.city);
    updateClient("intitule", value.intitule);
    updateClient("date_of_birth", value.date_of_birth);
  };

  const resetContact = () => {
    setContactDesc(undefined);
    updateClient("civilite", undefined);
    updateClient("contact_type", undefined);
    updateClient("id", undefined);
    updateClient("hubspot_contact_id", undefined);
    updateClient("firstname", undefined);
    updateClient("lastname", undefined);
    updateClient("email", undefined);
    updateClient("phone", undefined);
    updateClient("address", undefined);
    updateClient("zip", undefined);
    updateClient("city", undefined);
    updateClient("intitule", undefined);
    updateClient("date_of_birth", undefined);
  };

  const searchContact = (query) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getContact(0, query)
        .then((res) => {
          setContacts(
            res.data.map((s) => {
              s.description = renderContact(s);
              return s;
            })
          );
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  const updateClient = (field, value) => {
    contact[field] = value;
    updateContact('client', contact);
  }

  return (
    <Box width="large" gap="medium">
      <Box gap="xsmall">
        <Text style={{ fontWeight: "bold" }} size={size} textAlign="center">
          {title}
        </Text>
        {withCreateNew && (
          <Line
            size={size}
            leftLabel="Est un contact ?"
            rightChildren={
              <ToggleButton
                size={size}
                groups={[
                  {
                    label: "Oui",
                    selected: isContact === true,
                    onClick: () => setIsContact(true),
                  },
                  {
                    label: "Non",
                    selected: isContact === false,
                    onClick: () => {
                      resetContact();
                      setIsContact(false);
                    },
                  },
                ]}
              />
            }
          />
        )}
        {isContact && (
          <Line
            size={size}
            leftLabel="Contact"
            rightChildren={
              <Select
                options={contacts}
                size={size}
                value={contactDesc}
                labelKey={"description"}
                valueKey={{ key: "description", reduce: true }}
                onChange={({ value: nextValue }) =>
                  updateSelectedContact(nextValue)
                }
                onSearch={(text) => {
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  searchContact(escapedText);
                }}
              />
            }
          />
        )}

        <Box style={{ width: "100%" }} gap="xsmall">
          <Line
            leftLabel="Contact Type"
            size={size}
            rightChildren={
              <ToggleButton
                size={size}
                groups={
                  Object.keys(CONTACT_TYPE).map(key => CONTACT_TYPE[key])
                    .map(value => ({
                      label: value === 'PRO' ? 'Professionnel' : 'Particulier',
                      selected: contact.contact_type === value,
                      onClick: isContact
                        ? null
                        : () => updateClient("contact_type", value),
                    }))
                }
              />
            }
          />
          <Line
            leftLabel="Civilité"
            size={size}
            rightChildren={
              <ToggleButton
                size={size}
                groups={!isPro ? [
                  {
                    label: CIVILITY.ms,
                    selected: contact.civilite === CIVILITY.ms,
                    onClick: isContact
                      ? null
                      : () => updateClient("civilite", CIVILITY.ms),
                  },
                  {
                    label: CIVILITY.mr,
                    selected: contact.civilite === CIVILITY.mr,
                    onClick: isContact
                      ? null
                      : () => updateClient("civilite", CIVILITY.mr),
                  },
                  {
                    label: CIVILITY.en,
                    selected: contact.civilite === CIVILITY.en,
                    onClick: isContact
                      ? null
                      : () => updateClient("civilite", CIVILITY.en),
                  }
                ] :
                  [
                    {
                      label: CIVILITY.ms,
                      selected: contact.civilite === CIVILITY.ms,
                      onClick: isContact
                        ? null
                        : () => updateClient("civilite", CIVILITY.ms),
                    },
                    {
                      label: CIVILITY.mr,
                      selected: contact.civilite === CIVILITY.mr,
                      onClick: isContact
                        ? null
                        : () => updateClient("civilite", CIVILITY.mr),
                    }
                  ]
                }
              />
            }
          />
          <Box style={{ height: 5 }} />
          {contact.civilite !== CIVILITY.en && (
            <Box style={{ width: "100%" }} gap="xsmall">
              <Line
                size={size}
                leftLabel="Nom"
                rightChildren={
                  <CustomInput
                    size={size}
                    value={contact.lastname}
                    onChange={(event) =>
                      updateClient("lastname", event.target.value)
                    }
                    disabled={isContact}
                  />
                }
              />
              <Line
                leftLabel="Prénom"
                size={size}
                rightChildren={
                  <CustomInput
                    size={size}
                    value={contact.firstname}
                    onChange={(event) =>
                      updateClient("firstname", event.target.value)
                    }
                    disabled={isContact}
                  />
                }
              />
            </Box>
          )}
          {contact.civilite === CIVILITY.en && (
            <Line
              leftLabel="Raison social"
              size={size}
              rightChildren={
                <CustomInput
                  size={size}
                  value={contact.raisonSocial}
                  onChange={(event) =>
                    updateClient("raisonSocial", event.target.value)
                  }
                  disabled={isContact}
                />
              }
            />
          )}
          <Line
            leftLabel="Téléphone"
            size={size}
            rightChildren={
              <CustomInput
                value={contact.phone}
                size={size}
                onChange={(event) => updateClient("phone", event.target.value)}
                disabled={isContact}
              />
            }
          />
          <Line
            leftLabel="Email"
            size={size}
            rightChildren={
              <CustomInput
                value={contact.email}
                size={size}
                onChange={(event) => updateClient("email", event.target.value)}
                disabled={isContact}
              />
            }
          />
          {isPro &&
            <Line
              leftLabel="Intitule"
              size={size}
              rightChildren={
                <CustomInput
                  size={size}
                  value={contact.intitule}
                  onChange={(event) => updateClient("intitule", event.target.value)}
                />
              }
            />
          }
          {addressIncluded && (
            <AddressAutoComplet
              size={size}
              contact={contact}
              updateContact={updateClient}
              disabled={isContact}
            />
          )}
          {birthDayIncluded && (
            <Line
              size={size}
              leftLabel="Date de naissance"
              rightChildren={
                <DateInput
                  size={size}
                  disabled={isContact}
                  value={contact.date_of_birth}
                  onChange={(date) => updateClient("date_of_birth", date)}
                />
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const DisplayContact = ({
  contact,
  updateContact,
  addressIncluded,
  birthDayIncluded,
  title = "Renseigné vos informations",
  isPro = false,
  size = 'small' }) => {

  const [contactDesc, setContactDesc] = useState(
    contact.firstname +
    " " +
    contact.lastname +
    " " +
    `(${contact.email || ""})`
  );

  let timeoutId;

  const [contacts, setContacts] = useState(initContacts(contact));
  const searchContact = (query) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getContact(0, query)
        .then((res) => {
          setContacts(
            res.data.map((s) => {
              s.description = renderContact(s);
              return s;
            })
          );
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  const updateClient = (field, value) => {
    contact[field] = value;
    updateContact('client', contact);
  }

  return (
    <Box width="large" gap="medium">
      <Box gap="xsmall">
        <Text style={{ fontWeight: "bold" }} size={size} textAlign="center">
          {title}
        </Text>
        <Line
          size={size}
          leftLabel="Contact"
          rightChildren={
            <Select
              options={contacts}
              size={size}
              value={contactDesc}
              labelKey={"description"}
              valueKey={{ key: "description", reduce: true }}
              onChange={({ value: nextValue }) => {
                setContactDesc(nextValue);
                let value = contacts.find((s) => renderContact(s) === nextValue);
                updateContact('client', value)
              }
              }
              onSearch={(text) => {
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                searchContact(escapedText);
              }}
            />
          }
        />

        <Box style={{ width: "100%" }} gap="xsmall">
          <Line
            leftLabel="Contact Type"
            size={size}
            rightChildren={
              <ToggleButton
                size={size}
                groups={
                  Object.keys(CONTACT_TYPE).map(key => CONTACT_TYPE[key])
                    .map(value => ({
                      label: value === 'PRO' ? 'Professionnel' : 'Particulier',
                      selected: contact.contact_type === value,
                      onClick: () => updateClient('contact_type', value)
                    }))
                }

              />
            }
          />
          <Line
            leftLabel="Civilité"
            size={size}
            rightChildren={
              <ToggleButton
                size={size}
                groups={!isPro ? [
                  {
                    label: CIVILITY.ms,
                    selected: contact.civilite === CIVILITY.ms,
                    onClick: () => updateClient('civilite', CIVILITY.ms)
                  },
                  {
                    label: CIVILITY.mr,
                    selected: contact.civilite === CIVILITY.mr,
                    onClick: () => updateClient('civilite', CIVILITY.mr)
                  },
                  {
                    label: CIVILITY.en,
                    selected: contact.civilite === CIVILITY.en,
                    onClick: () => updateClient('civilite', CIVILITY.en)
                  }
                ] :
                  [
                    {
                      label: CIVILITY.ms,
                      selected: contact.civilite === CIVILITY.ms,
                      onClick: () => updateClient('civilite', CIVILITY.ms)
                    },
                    {
                      label: CIVILITY.mr,
                      selected: contact.civilite === CIVILITY.mr,
                      onClick: () => updateClient('civilite', CIVILITY.mr)
                    }
                  ]
                }
              />
            }
          />
          <Box style={{ height: 5 }} />
          {contact.civilite !== CIVILITY.en && (
            <Box style={{ width: "100%" }} gap="xsmall">
              <Line
                size={size}
                leftLabel="Nom"
                rightChildren={
                  <CustomInput
                    size={size}
                    value={contact.lastname}
                    onChange={event => updateClient('lastname', event.target.value)}
                  />
                }
              />
              <Line
                leftLabel="Prénom"
                size={size}
                rightChildren={
                  <CustomInput
                    size={size}
                    value={contact.firstname}
                    onChange={event => updateClient('firstname', event.target.value)}
                  />
                }
              />
            </Box>
          )}
          {contact.civilite === CIVILITY.en && (
            <Line
              leftLabel="Raison social"
              size={size}
              rightChildren={
                <CustomInput
                  size={size}
                  value={contact.raisonSocial}
                  onChange={event => updateClient('raisonSocial', event.target.value)}
                />
              }
            />
          )}
          <Line
            leftLabel="Téléphone"
            size={size}
            rightChildren={
              <CustomInput
                value={contact.phone}
                size={size}
                onChange={event => updateClient('phone', event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Email"
            size={size}
            rightChildren={
              <CustomInput
                value={contact.email}
                size={size}
                onChange={event => updateClient('email', event.target.value)}
              />
            }
          />
          {isPro &&
            <Line
              leftLabel="Intitule"
              size={size}
              rightChildren={
                <CustomInput
                  size={size}
                  value={contact.intitule}
                  onChange={(event) => updateClient("intitule", event.target.value)}
                />
              }
            />
          }
          {addressIncluded && (
            <AddressAutoComplet
              size={size}
              contact={contact}
              updateContact={updateClient}
            />
          )}
          {birthDayIncluded && (
            <Line
              size={size}
              leftLabel="Date de naissance"
              rightChildren={
                <DateInput
                  size={size}
                  value={contact.date_of_birth}
                  onChange={(date) => updateClient("date_of_birth", date)}
                />
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

const NewContact = ({
  contact,
  updateContact,
  addressIncluded,
  birthDayIncluded,
  isPro = false,
  size = 'small'
}) => {


  const updateClient = (field, value) => {
    contact[field] = value;
    updateContact('client', contact);
  }
  return (
    <Box style={{ width: "100%" }} gap="xsmall">
      <Line
        leftLabel="Contact Type"
        size={size}
        rightChildren={
          <ToggleButton
            size={size}
            groups={
              Object.keys(CONTACT_TYPE).map(key => CONTACT_TYPE[key])
                .map(value => ({
                  label: value === 'PRO' ? 'Professionnel' : 'Particulier',
                  selected: contact.contact_type === value,
                  onClick: () => updateClient("contact_type", value),
                }))
            }
          />
        }
      />
      <Line
        leftLabel="Civilité"
        size={size}
        rightChildren={
          <ToggleButton
            size={size}
            groups={!isPro ? [
              {
                label: CIVILITY.ms,
                selected: contact.civilite === CIVILITY.ms,
                onClick: () => updateClient("civilite", CIVILITY.ms),
              },
              {
                label: CIVILITY.mr,
                selected: contact.civilite === CIVILITY.mr,
                onClick: () => updateClient("civilite", CIVILITY.mr),
              },
              {
                label: CIVILITY.en,
                selected: contact.civilite === CIVILITY.en,
                onClick: () => updateClient("civilite", CIVILITY.en),
              }
            ] :
              [
                {
                  label: CIVILITY.ms,
                  selected: contact.civilite === CIVILITY.ms,
                  onClick: () => updateClient("civilite", CIVILITY.ms),
                },
                {
                  label: CIVILITY.mr,
                  selected: contact.civilite === CIVILITY.mr,
                  onClick: () => updateClient("civilite", CIVILITY.mr),
                }
              ]
            }
          />
        }
      />
      <Box style={{ height: 5 }} />
      {contact.civilite !== CIVILITY.en && (
        <Box style={{ width: "100%" }} gap="xsmall">
          <Line
            size={size}
            leftLabel="Nom"
            rightChildren={
              <CustomInput
                size={size}
                value={contact.lastname}
                onChange={(event) =>
                  updateClient("lastname", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Prénom"
            size={size}
            rightChildren={
              <CustomInput
                size={size}
                value={contact.firstname}
                onChange={(event) =>
                  updateClient("firstname", event.target.value)
                }
              />
            }
          />
        </Box>
      )}
      {contact.civilite === CIVILITY.en && (
        <Line
          leftLabel="Raison social"
          size={size}
          rightChildren={
            <CustomInput
              size={size}
              value={contact.raisonSocial}
              onChange={(event) =>
                updateClient("raisonSocial", event.target.value)
              }
            />
          }
        />
      )}
      <Line
        leftLabel="Téléphone"
        size={size}
        rightChildren={
          <CustomInput
            value={contact.phone}
            size={size}
            onChange={(event) => updateClient("phone", event.target.value)}
          />
        }
      />
      <Line
        leftLabel="Email"
        size={size}
        rightChildren={
          <CustomInput
            value={contact.email}
            size={size}
            onChange={(event) => updateClient("email", event.target.value)}
          />
        }
      />
      {isPro &&
        <Line
          leftLabel="Intitule"
          size={size}
          rightChildren={
            <CustomInput
              size={size}
              value={contact.intitule}
              onChange={(event) => updateClient("intitule", event.target.value)}
            />
          }
        />
      }
      {addressIncluded && (
        <AddressAutoComplet
          size={size}
          contact={contact}
          updateContact={updateClient}
        />
      )}
      {birthDayIncluded && (
        <Line
          size={size}
          leftLabel="Date de naissance"
          rightChildren={
            <DateInput
              size={size}
              value={contact.date_of_birth}
              onChange={(date) => updateClient("date_of_birth", date)}
            />
          }
        />
      )}
    </Box>
  )
}

export const ContactSelectV2 = ({
  contact,
  updateContact,
  addressIncluded,
  birthDayIncluded,
  withCreateNew = true,
  title = "Renseigné vos informations",
  isPro = false,
  size = 'small'
}) => {

  const [isContact, setIsContact] = useState(
    contact.id ? true : withCreateNew ? false : true
  );
  return (
    <Box width="large" gap="medium">
      <Box gap="xsmall">
        <Text style={{ fontWeight: "bold" }} size={size} textAlign="center">
          {title}
        </Text>
        {withCreateNew && (
          <Line
            size={size}
            leftLabel="Est un contact ?"
            rightChildren={
              <ToggleButton
                size={size}
                groups={[
                  {
                    label: "Oui",
                    selected: isContact === true,
                    onClick: () => setIsContact(true),
                  },
                  {
                    label: "Non",
                    selected: isContact === false,
                    onClick: () => {
                      delete contact.id;
                      delete contact._id;
                      updateContact('client', { ...contact });
                      setIsContact(false);
                    },
                  },
                ]}
              />
            }
          />
        )}
        {isContact ?
          <DisplayContact
            contact={contact}
            updateContact={updateContact}
            addressIncluded={addressIncluded}
            birthDayIncluded={birthDayIncluded}
            title="Renseigné vos informations"
            isPro={isPro}
            size='small'
          /> :
          <NewContact
            contact={contact}
            updateContact={updateContact}
            addressIncluded={addressIncluded}
            birthDayIncluded={birthDayIncluded}
            isPro={isPro}
            size='small'
          />

        }
      </Box>
    </Box>
  )
}

export const ContactSelectSimple = ({
  contact,
  callbackSelected,
  size = 'small'
}) => {

  const [contacts, setContacts] = useState(initContacts(contact));
  const [contactDesc, setContactDesc] = useState(
    contact.firstname +
    " " +
    contact.lastname +
    " " +
    `(${contact.email || ""})`
  );

  let timeoutId;

  const searchContact = (query) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getContact(0, query)
        .then((res) => {
          setContacts(
            res.data.map((s) => {
              s.description = renderContact(s);
              return s;
            })
          );
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  return (

    <Line
      size={size}
      leftLabel="Contact"
      rightChildren={
        <Select
          options={contacts}
          size={size}
          style={{background: '#fff'}}
          value={contactDesc}
          labelKey={"description"}
          valueKey={{ key: "description", reduce: true }}
          onChange={({ value: nextValue }) => {
            setContactDesc(nextValue);
            let value = contacts.find((s) => renderContact(s) === nextValue);
            callbackSelected(value)
          }
          }
          onSearch={(text) => {
            const escapedText = text.replace(
              /[-\\^$*+?.()|[\]{}]/g,
              "\\$&"
            );
            searchContact(escapedText);
          }}
        />
      }
    />
  )
}

export default ContactSelect;
