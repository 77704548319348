import { TextInput } from 'grommet';
import React from 'react';

const inputStyle = { background: '#EDEDED', borderRadius: 10, border: 'none', padding: 6 };

const CustomInput = ({ value, onChange, placeholder, disabled, style, size = 'medium', type = "text", ...rest }) => {

    return (
        <TextInput
            placeholder={placeholder}
            style={{ ...inputStyle, ...style }}
            value={value || ''}
            size={size}
            type={type}
            onChange={(event) => {
                if (type === 'number'){
                    onChange({...event, target: {...event.target, value: event.target.value.replace(',', '.')}});
                }else{
                    onChange(event);
                }
            }}
            disabled={disabled}
            {...rest}
        />
    )
}

const inputStyleV2 = { background: '#EDEDED', borderRadius: 10, border: 'none', padding: '5px 14px' };
export const CustomInputV2 = ({ value, onChange, placeholder, disabled, style, size = 'medium', type = "text", ...rest }) => {

    return (
        <TextInput
            placeholder={placeholder}
            style={{ ...inputStyleV2, ...style }}
            value={value || ''}
            size={size}
            type={type}
            onChange={(event) => {
                if (type === 'number'){
                    onChange({...event, target: {...event.target, value: event.target.value.replace(',', '.')}});
                }else{
                    onChange(event);
                }
            }}
            disabled={disabled}
            {...rest}
        />
    )
}

export default CustomInput;