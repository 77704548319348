const PROSPECT = "Prospect";
const DEAL_POSSIBLE = "Deal possible";
const A_RAPPELER_PLUS_TARD = "À rappeler plus tard";
const DEAL_POSSIBLE_REMPLI = "Deal possible rempli";
const DEVIS = "Devis";
const DEVIS_VALIDE = "Devis validé";
const EN_ATTENTE_DE_RESILIATION = "En attente de résiliation";
const EN_ATTENTE_DE_FORMALITE_MEDICALE = "En attente de formalité médicale";
const CONTRAT_ENVOYE = "Contrat envoyé";
const CONTRAT_CLOTURE = "Contrat clôturé";
const EN_ATTENTE_BANQUE = "En attente de la banque";
const WAITING_BANK = "En attente de la banque";
const PERDU = "Perdu";
const ARCHIVE = "Archivé";
const RESILIE = "Résilié";
const ETUDE = "Étude";
const EDITION = "Édition";

const RecapStatusColor = (status) => {
  switch (status) {
    case PROSPECT:
      return "#bfbfbf";
    case DEAL_POSSIBLE:
      return "#73A6EC";
    case A_RAPPELER_PLUS_TARD:
      return "#17a2b8";
    case DEAL_POSSIBLE_REMPLI:
      return "#73AAAA";
    case DEVIS:
      return "#FC0000";
    case DEVIS_VALIDE:
      return "#FF6E6E";
    case CONTRAT_ENVOYE:
    case WAITING_BANK:
      return "#FFD77C";
    case CONTRAT_CLOTURE:
      return "#33BC00";
    case PERDU:
    case RESILIE:
    case ARCHIVE:
    case EN_ATTENTE_DE_RESILIATION:
    case EN_ATTENTE_DE_FORMALITE_MEDICALE:
    case ETUDE:
    case EDITION:

      return "#000";
    default:
      return "#73A6EC";
  }
};

module.exports = {
  PROSPECT,
  A_RAPPELER_PLUS_TARD,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  CONTRAT_ENVOYE,
  EN_ATTENTE_BANQUE,
  CONTRAT_CLOTURE,
  EN_ATTENTE_DE_RESILIATION,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  PERDU,
  ARCHIVE,
  RESILIE,
  ETUDE,
  EDITION,
  RecapStatusColor,
  isDealClosable: deal => deal.status === CONTRAT_CLOTURE || deal.status === EN_ATTENTE_BANQUE
};
