import { Box, Card, Text } from 'grommet';
import React from 'react';
import StandardText from '../standard_text';

const CustomText = ({ label, size }) => <Text style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
    size={size || 'small'} textAlign="end" >{label}</Text>
const Line = ({ leftLabel, rightChildren, widthLeft, widthRight, size }) => (
    <Box direction='row' gap="medium" style={{ width: '100%' }} align="center" alignSelf="center">
        <Box style={{ width: widthLeft || '40%' }} >
            {leftLabel && <CustomText
                size={size} label={leftLabel + " :"} />}
        </Box>
        <Box style={{ width: widthRight || '60%' }} >
            {rightChildren}
        </Box>
    </Box>
)

export default Line;

const SmallCustomText = ({ label, bold }) => <Text style={{ fontWeight: bold ? 'bold' : 'normal' }} size="xsmall" >
    <span >{label.split('::').map((s, idx) =>
        idx % 2 === 0 ? <>{s}</> : <strong>{s}</strong>
    )}</span>
</Text>

export const SmallLine = ({ leftLabel, rightChildren, widthLeft, widthRight, bold = false, align='center' }) => (
    <Box direction='row' gap="medium" style={{ width: '100%' }} align={align}>
        <Box style={{ width: widthLeft || '40%' }} >
            {leftLabel && <SmallCustomText bold={bold} label={leftLabel + " :"} />}
        </Box>
        <Box style={{ width: widthRight || '60%' }} >
            {rightChildren}
        </Box>
    </Box>
)

export const IdDisplay = ({id}) => (
    <Box width="xlarge" gap="small" >
        <Card style={{ width: "100%" }} pad="small" gap="small" direction='row'
            justify='center' align='center'
        >
            <StandardText label={`ID:`} size='small' />
            <StandardText label={`${id}`} size='small' style={{fontWeight: 'bold'}} />
        </Card>
    </Box>
)