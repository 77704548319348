const CIVILITY = {
    mr: 'Monsieur',
    ms: 'Madame',
    en: 'Société'
}
const CONTACT_TYPE = {
    perso: 'PERSO',
    PRO: 'PRO'
}
const CLIENT_TYPE = {
    colocataire: 'Colocataire',
    locataire: 'Locataire',
    proprietaire: 'Propriétaire'
}
const BIEN_TYPE = {
    maison: 'Maison',
    appartement: 'Appartement'
}
const KEY_TYPE = {
    keybox: 'Keybox',
    serrureConnecte: 'Sérrure connecté',
    serrureClassique: 'Sérrure classique'
}
const FLOOR = {
    rdc: 'Rez de Chaussée',
    first_stage: '1er étage',
    intermediaire: 'Entre le 1er étage et le dernier étage',
    dernier: 'Dernier Etage'
}
const FLOOR_MH = {
    rdc: 'Rez de Chaussée',
    first_stage: '1er étage',
    intermediaire: 'Entre le 1er étage et le dernier étage',
    dernier: 'Dernier Etage'
}
const LEVEL = {
    one_level_wtho_ss: "Plain-pied sans sous-sol",
    one_level_wth_ss: "Plain-pied avec sous-sol",
    sev_level_wtho_ss: "Plusieurs niveaux sans sous-sol",
    sev_level_wth_ss: "Plusieurs niveaux avec sous-sol",
}
const RESIDENCE_TYPE = {
    principale: 'Résidence principale',
    secondaire: 'Résidence secondaire',
    location: 'Logement en location'
}

const ASSURANCE_FOR = {
    me: 'Pour moi',
    locataire:'Pour mes locataires'
}

const OUT_HOUSE_DURATION = {
    short: 'Inférieur ou égal à 60 jours',
    medium: 'Entre 60 et 120 jours',
    long: 'Plus de 120 jours'
}
const CONSTRUCTION_TYPE = {
    wood: 'Bois',
    other: 'Autres',
}
const FRACTIONNEMENT_TYPE = {
    annual: 'Annuel',
    monthly: 'Mensuel',
}
const EMPRUNTEUR_TYPE = {
    seul: 'Seul',
    co_emprunteur: 'Co-emprunteur',
}
const COVER_TYPE = [
    'Ardoise',
    'Bois',
    'Chaume',
    'Shingle',
    'Terrasse',
    'Tuiles',
    'Autres',
]

module.exports = {
    CIVILITY,
    CONTACT_TYPE,
    RESIDENCE_TYPE,
    BIEN_TYPE,
    CLIENT_TYPE,
    FLOOR,
    FLOOR_MH,
    LEVEL,
    OUT_HOUSE_DURATION,
    CONSTRUCTION_TYPE,
    FRACTIONNEMENT_TYPE,
    COVER_TYPE,
    KEY_TYPE,
    ASSURANCE_FOR,
    EMPRUNTEUR_TYPE
}