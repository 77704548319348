import { Box, Text, TextInput } from 'grommet';
import React from "react";

const LineModification = ({ text, field, value, onChange, direction = 'row', disabled = false, type = 'text' , width="large"}) => (
    <Box direction={direction} gap="xsmall" justify="start" align="center" width={width} >
        <Box style={{ width: direction === 'row' ? '50%' : '100%' }}>
            <Text size="xsmall" color={'#707070'}>{text}</Text>
        </Box>
        <Box style={{ width: direction === 'row' ? '50%' : '100%' }}>
            <TextInput readOnly={disabled} style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }} size="xsmall" value={value} onChange={event => onChange(field, event.target.value)} type={type} />
        </Box>

    </Box>
)

export default LineModification;
export const LineDisplay = ({ field, value, size = 'xsmall' }) => (
    <Text size={size} color={'#707070'}>
        {field ? `${field} : ` : ""}
        <b> {value} </b>
    </Text>
)
export const CustomLineModification = ({ text, value, onChange, size = 'xsmall', rightComponent }) => (
    <Box direction={'row'} gap="xsmall" justify="start" align="center" width={'large'}>
        <Box style={{ width: '50%' }}>
            <Text size={size} color={'#707070'}>{text}</Text>
        </Box>
        <Box style={{ width: '50%' }}>
            {rightComponent ?
                rightComponent :
                <TextInput style={{ background: '#EDEDED', borderRadius: 10, border: 'none', }} size={size} value={value} onChange={onChange} />
            }
        </Box>

    </Box>
)